import { red } from './red.js';
import { yellow } from './yellow.js';
import { amber } from './amber.js';
import { green } from './green.js';
import { turquoise } from './turquoise.js';
import { blue } from './blue.js';
import { darkBlue } from './darkBlue.js';
import { deepBlue } from './deepBlue.js';
import { magenta } from './magenta.js';
import { purple } from './purple.js';

const dataVisualization = {
  red,
  yellow,
  amber,
  green,
  turquoise,
  blue,
  darkBlue,
  deepBlue,
  magenta,
  purple
};

export { dataVisualization };
