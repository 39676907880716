const grey = {
  100: '#686868',
  80: '#969696',
  60: '#BFBEBE',
  40: '#D8D8D9',
  20: '#F4F4F4',
  10: '#FAFAFA'
};

export { grey };
