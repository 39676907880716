const black = {
  100: '#303030',
  80: '#484848',
  60: '#969696',
  40: '#BFBEBE',
  20: '#D8D8D9',
  10: '#F4F4F4'
};

export { black };
