const deepBlue = {
  100: '#1F61A3',
  60: '#4D81B7',
  40: '#A6C0DB',
  20: '#D3E0ED',
  10: '#E9EFF6'
};
const deepRed = {
  100: '#B6000F',
  60: '#D3785E',
  40: '#E2A690',
  20: '#F1D3C7',
  10: '#F8E9E3'
};
const defrostStroke = {
  100: '#F1C40F'
};
const defrostFill = {
  100: '#F9F73E'
};
const divergingColors = {
  deepBlue,
  deepRed,
  defrostStroke,
  defrostFill
};

export { divergingColors };
