const deepRed = {
  100: '#B6000F',
  80: '#C44A34',
  60: '#D3785E',
  40: '#E2A690',
  20: '#F1D3C7',
  10: '#F8E9E3'
};

export { deepRed };
